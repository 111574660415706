<template>
  <div class="flex">
    <NavBarLayout />
    <div class="flex-1 dark:bg-black min-h-screen bg-gray-100 mt-14 md:mt-0">
      <div class="py-5 flex justify-between px-4 flex-col sm:flex-row gap-1">
        <div class="text-2xl font-semibold dark:text-white">Insights</div>
        <div
          @click="openModal"
          class="flex items-center p-2 rounded-lg bg-gray-200 dark:bg-gray-800 shadow-md space-x-4 cursor-pointer"
        >
          <!-- Left section: "Last X days" -->
          <span
            class="text-xs font-semibold text-gray-600 dark:text-gray-300 bg-gray-300 dark:bg-gray-700 px-2 py-1 rounded"
          >
            Last {{ dayDifference }} days
          </span>

          <!-- Middle section: Main Date Range -->
          <div class="flex flex-col">
            <div class="flex items-center">
              <!-- Main Date Range -->
              <div class="text-sm font-semibold dark:text-white">
                {{ formattedStartDate }} - {{ formattedEndDate }}
              </div>

              <!-- Right section: Dropdown Icon -->
              <div class="ml-2">
                <svg
                  :class="{ 'rotate-180': isModalOpen }"
                  class="transition-transform w-4 h-4 text-gray-600 dark:text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </div>
            <!-- Compare Date Range (shown when comparison is active) -->

            <span
              v-if="isCompareChecked"
              class="text-xs text-gray-600 dark:text-gray-400"
            >
              Compare: {{ formattedCompareStartDate }} -
              {{ formattedCompareEndDate }}
            </span>
          </div>
        </div>
      </div>
      <DatePicker
        :startDate="startDate"
        :endDate="endDate"
        compareRequire="true"
        :compareStartDate="compareStartDate"
        :compareEndDate="compareEndDate"
        :isCompareChecked="isCompareChecked"
        :presets="presets"
        :isModalOpen="isModalOpen"
        @update:startDate="updateStartDate"
        @update:endDate="updateEndDate"
        @update:compareStartDate="updateCompareStartDate"
        @update:compareEndDate="updateCompareEndDate"
        @toggleCompare="toggleCompare"
        @close="closeModal"
      />
      <!-- Layout for insight -->
      <InsightLayout />
    </div>
  </div>
</template>
<script setup>
import NavBarLayout from '@/layouts/NavBarLayout.vue';
import DatePicker from '@/components/DatePicker.vue';
import { calculateDate } from '@/utils/helperFunctions';
import { presets } from '@/utils/constantData';
import { computed, onMounted, ref } from 'vue';
import { useDateStore } from '@/stores/dateStore';
import InsightLayout from '@/layouts/InsightLayout.vue';

const defaultStartDate = calculateDate(-31);
const defaultEndDate = calculateDate(-1);
const defaultCompareStartDate = calculateDate(-61);
const defaultCompareEndDate = calculateDate(-31);
const startDate = ref(defaultStartDate);
const endDate = ref(defaultEndDate);
const compareStartDate = ref(defaultCompareStartDate);
const compareEndDate = ref(defaultCompareEndDate);
const isModalOpen = ref(false);
const isCompareChecked = ref(true);
const dateStore = useDateStore();
// Calculate the difference in days between start and end dates
const dayDifference = computed(() => {
  return Math.ceil(
    (new Date(endDate.value) - new Date(startDate.value)) /
      (1000 * 60 * 60 * 24),
  );
});

// Format the main date range
const formattedStartDate = computed(() => {
  const options = { day: 'numeric', month: 'short' };
  return new Date(startDate.value).toLocaleDateString('en-US', options);
});

const formattedEndDate = computed(() => {
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return new Date(endDate.value).toLocaleDateString('en-US', options);
});

// Format the comparison date range
const formattedCompareStartDate = computed(() => {
  const options = { day: 'numeric', month: 'short' };
  return new Date(compareStartDate.value).toLocaleDateString('en-US', options);
});

const formattedCompareEndDate = computed(() => {
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return new Date(compareEndDate.value).toLocaleDateString('en-US', options);
});
const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};

const updateStartDate = (newStartDate) => {
  dateStore.setInsightDate('currentStart', newStartDate);
  startDate.value = newStartDate;
};

const updateEndDate = (newEndDate) => {
  dateStore.setInsightDate('currentEnd', newEndDate);
  endDate.value = newEndDate;
};

const updateCompareStartDate = (date) => {
  dateStore.setInsightDate('pastStart', date);
  compareStartDate.value = date;
};

const updateCompareEndDate = (date) => {
  dateStore.setInsightDate('pastEnd', date);
  compareEndDate.value = date;
};

const toggleCompare = () => {
  isCompareChecked.value = !isCompareChecked.value;
};

// Set the default dates in the store when the component is mounted
onMounted(() => {
  if (
    !dateStore.insightDate.currentStart ||
    !dateStore.insightDate.currentEnd
  ) {
    dateStore.setInsightDate('currentStart', startDate.value);
    dateStore.setInsightDate('currentEnd', endDate.value);
    dateStore.setInsightDate('pastStart', compareStartDate.value);
    dateStore.setInsightDate('pastEnd', compareEndDate.value);
  }
});
</script>
