<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden pt-4 px-2 md:px-4 bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 min-h-full flex-grow"
  >
    <!-- Header section with title -->
    <div class="flex justify-between flex-wrap">
      <div class="text-base md:text-xl font-semibold mb-2 dark:text-white">
        Device Distribution
      </div>
      <div
        class="flex text-sm items-center text-gray-500 dark:text-gray-300 px-4 gap-1 mb-2"
      >
        <Calendar :size="16" />
        {{ formattedStartDate }} - {{ formattedEndDate }}
      </div>
    </div>
    <!-- ApexChart component with adjusted height -->
    <div
      v-if="!loading"
      class="flex-1 flex justify-center items-center flex-col lg:flex-row"
    >
      <apexchart
        type="donut"
        :options="chartOptions"
        :series="chartSeries"
        class=""
      ></apexchart>
      <div
        class="text-xs md:text-base lg:text-lg flex lg:flex-col gap-2 md:gap-0 flex-wrap justify-center"
      >
        <span
          v-for="(item, index) in labelData"
          :key="index"
          :style="{ color: colors[index] }"
        >
          ● {{ item }}
        </span>
      </div>
    </div>
    <div v-else class="flex flex-1 justify-center">
      <StyledLoader />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import axios from '@/utils/privateApiInstance';
import { useTeamStore } from '@/stores/teamStore';
import { formatNumber } from '@/utils/helperFunctions';
import { useDateStore } from '@/stores/dateStore';
import { Calendar } from 'lucide-vue-next';
import StyledLoader from '../StyledLoader.vue';

// Reactive variables
const teamStore = useTeamStore();
const dateStore = useDateStore();
const currentTeam = ref(teamStore.current_team);
const data = ref();
const labelData = ref();
const loading = ref(false);

// Chart series data
const chartSeries = ref([]); // Placeholder data
const chartLabels = ref([]); // Placeholder labels
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
  },
  {
    immediate: true,
  },
);
const colors = ['#f59e0b', '#fbbf24', '#0000FF', '#60a5fa', '#FF7F50'];
// Chart configuration options
const chartOptions = computed(() => ({
  chart: {
    id: 'device-chart',
    type: 'donut',

    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },

  colors: colors,

  labels: chartLabels.value,
  legend: {
    show: false,
  },
  tooltip: {
    theme: 'dark',
  },
  noData: {
    text: 'Waiting for data...',
  },
  dataLabels: {
    enabled: false,
  },
}));
// Fetch device data from API
const fetchData = async () => {
  try {
    loading.value = true;
    const response = await axios.get(
      '/google-analytics-insights/device-driving-traffic-trends-metrics',
      {
        params: {
          team_id: currentTeam.value ? Number(currentTeam.value.id) : 0,
          start_date: dateStore.insightDate.currentStart,
          end_date: dateStore.insightDate.currentEnd,
          past_start_date: dateStore.insightDate.pastStart,
          past_end_date: dateStore.insightDate.pastEnd,
        },
      },
    );

    data.value = JSON.parse(response.data?.items.data);
  } catch (error) {
    data.value = [];
  } finally {
    loading.value = false;
  }
};

// Watch for changes in props to refetch data
watch(
  () => dateStore.insightDate, // Watch for changes in these values
  async () => {
    await fetchData(); // Ensure DOM updates before calling API
  },
  { immediate: true, deep: true },
);

// Watcher to update chart data and options
watch(
  () => data?.value,
  (newData) => {
    if (Array.isArray(newData)) {
      // // Extract session types and their counts
      const labels = newData.map((items) => items[0]);
      const series = newData.map((item) => item[1]); // Session counts
      labelData.value = newData.map(
        (item) => item[0].toUpperCase() + '-' + formatNumber(item[1]),
      );
      // // Update chart data
      chartLabels.value = labels;
      chartSeries.value = series;
    }
  },
  { immediate: true },
);
// Format the main date range
const formattedStartDate = computed(() => {
  const options = { year: 'numeric', month: 'short' };
  return new Date(dateStore?.insightDate?.pastStart).toLocaleDateString(
    'en-US',
    options,
  );
});

const formattedEndDate = computed(() => {
  const options = { year: 'numeric', month: 'short', year: 'numeric' };
  return new Date(dateStore?.insightDate?.currentEnd).toLocaleDateString(
    'en-US',
    options,
  );
});
</script>
