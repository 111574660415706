<template>
  <PaginatedTable
    title="Top Campaigns"
    subtitle="Based on Lowest Cost Per Conversion "
    column1Title="Campaign Name"
    column2Title="Cost Per Conversion"
    apiUrl="/google-ads-insights/campaign-with-lowest-cost-per-conversion"
    :displayIndex="index"
    :formatter="formatData"
  />
</template>

<script setup>
import PaginatedTable from '../PaginatedTable.vue';
const index = [1, 2];
const formatData = (data) => '₹' + data.toFixed(2);
</script>
