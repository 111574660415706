import { defineStore } from 'pinia';

const initialInsightDate = {
  currentStart: '',
  currentEnd: '',
  pastStart: '',
  pastEnd: '',
};
const initialDashboardDate = {
  currentStart: '',
  currentEnd: '',
};
export const useDateStore = defineStore('date', {
  state: () => ({
    insightDate: initialInsightDate,
    dashboardDate: initialDashboardDate,
  }),
  actions: {
    setInsightDate(name, value) {
      this.insightDate[name] = value;
    },
    setDashboardDate(name, value) {
      this.dashboardDate[name] = value;
    },
    clear() {
      this.insightDate = initialInsightDate;
      this.dashboardDate = initialDashboardDate;
    },
  },
});
