<template>
  <transition name="fade">
    <div
      v-if="isModalOpen"
      class="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex items-center justify-center px-4"
    >
      <div class="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-md">
        <!-- Modal Header -->
        <div class="flex justify-between items-center mb-4">
          <h3 class="text-lg font-semibold dark:text-white">
            Select Date Range
          </h3>
          <button
            @click="closeModal"
            class="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
          >
            <X />
          </button>
        </div>

        <!-- Date Range Inputs -->
        <div class="mb-4">
          <div class="flex flex-col sm:flex-row gap-2">
            <div class="flex-1">
              <label class="dark:text-white">From:</label>
              <input
                type="date"
                v-model="localStartDate"
                @change="onDateChange"
                class="border border-gray-300 rounded-lg px-3 py-2 dark:bg-gray-900 dark:text-white dark:border-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
              />
            </div>
            <div class="flex-1">
              <label class="dark:text-white">To:</label>
              <input
                type="date"
                v-model="localEndDate"
                @change="onDateChange"
                class="border border-gray-300 rounded-lg px-3 py-2 dark:bg-gray-900 dark:text-white dark:border-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
              />
            </div>
          </div>
        </div>

        <!-- Checkbox for Compare -->
        <div
          class="flex items-center justify-around mb-4"
          v-if="compareRequire"
        >
          <label
            for="compare-checkbox"
            class="text-lg font-semibold dark:text-white flex-1"
          >
            Compare
          </label>
          <button
            @click="toggleCompare"
            :class="[
              'relative inline-flex items-center justify-between w-10 h-5 p-1 rounded-full transition-colors duration-300 ease-in-out',
              localIsCompareChecked ? 'bg-blue-500' : 'bg-gray-400',
            ]"
            aria-checked="false"
            role="switch"
          >
            <span
              :class="[
                'block w-5 h-5 bg-white rounded-full shadow-md transform transition-transform duration-300 ease-in-out',
                localIsCompareChecked ? 'translate-x-4' : '-translate-x-1',
              ]"
            ></span>
          </button>
        </div>

        <!-- Additional Date Inputs for Comparison if Checkbox is Checked -->
        <div v-if="localIsCompareChecked" class="mb-4">
          <div class="flex flex-col sm:flex-row gap-2">
            <div class="flex-1">
              <label class="dark:text-white">Compare From:</label>
              <input
                type="date"
                v-model="localCompareStartDate"
                @change="onCompareDateChange"
                class="border border-gray-300 rounded-lg px-3 py-2 dark:bg-gray-900 dark:text-white dark:border-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
              />
            </div>
            <div class="flex-1">
              <label class="dark:text-white">Compare To:</label>
              <input
                type="date"
                v-model="localCompareEndDate"
                @change="onCompareDateChange"
                class="border border-gray-300 rounded-lg px-3 py-2 dark:bg-gray-900 dark:text-white dark:border-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
              />
            </div>
          </div>
        </div>

        <!-- Presets -->
        <div class="mt-4">
          <p class="dark:text-white">Presets:</p>
          <div class="space-y-2">
            <button
              v-for="preset in presets"
              :key="preset.label"
              @click="applyPreset(preset)"
              class="block w-full text-left px-4 py-2 border rounded dark:bg-gray-950 dark:text-white dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-700"
            >
              {{ preset.label }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps } from 'vue';
import { X } from 'lucide-vue-next';
import { toast } from 'vue3-toastify';
import { calculateDate } from '@/utils/helperFunctions';

// Define emits
const emit = defineEmits([
  'update:startDate',
  'update:endDate',
  'update:compareStartDate',
  'update:compareEndDate',
  'toggleCompare',
  'close',
]);

// Define props
const props = defineProps({
  startDate: String,
  endDate: String,
  compareStartDate: String,
  compareEndDate: String,
  presets: Array,
  isCompareChecked: Boolean,
  isModalOpen: Boolean,
  compareRequire: String,
});

// Local state for date inputs and comparison checkbox
const localStartDate = ref(props.startDate || calculateDate(-7));
const localEndDate = ref(props.endDate || calculateDate(0));
const localCompareStartDate = ref(props.compareStartDate || calculateDate(-14));
const localCompareEndDate = ref(props.compareEndDate || calculateDate(-8));
const localIsCompareChecked = ref(props.isCompareChecked || false);

// Watch for changes from parent to update local states
watch(
  () => props.startDate,
  (newVal) => {
    localStartDate.value = newVal || calculateDate(-7);
  },
);
watch(
  () => props.endDate,
  (newVal) => {
    localEndDate.value = newVal || calculateDate(0);
  },
);
watch(
  () => props.compareStartDate,
  (newVal) => {
    localCompareStartDate.value = newVal || calculateDate(-14);
  },
);
watch(
  () => props.compareEndDate,
  (newVal) => {
    localCompareEndDate.value = newVal || calculateDate(-8);
  },
);
watch(
  () => props.isCompareChecked,
  (newVal) => {
    localIsCompareChecked.value = newVal || false;
  },
);

// Emit updates when dates are changed
const onDateChange = () => {
  if (validateDateRange(localStartDate.value, localEndDate.value)) {
    emit('update:startDate', localStartDate.value);
    emit('update:endDate', localEndDate.value);
  }
};

const onCompareDateChange = () => {
  if (
    validateDateRange(localCompareStartDate.value, localCompareEndDate.value) &&
    validateCompareToMainDates()
  ) {
    emit('update:compareStartDate', localCompareStartDate.value);
    emit('update:compareEndDate', localCompareEndDate.value);
  }
};

// Validate date ranges
const validateDateRange = (start, end) => {
  const today = new Date().toISOString().split('T')[0]; // Today's date in YYYY-MM-DD format

  if (!start || !end) {
    toast.error('Please select both start and end dates.');
    return false;
  }

  if (start > today || end > today) {
    toast.error('Dates cannot be in the future.');
    return false;
  }

  if (end < start) {
    toast.error('End date cannot be earlier than start date.');
    return false;
  }

  return true;
};

// Validate that compare dates are not later than main date range
const validateCompareToMainDates = () => {
  if (
    localCompareEndDate.value > localEndDate.value ||
    localCompareStartDate.value > localStartDate.value
  ) {
    toast.error(
      'Comparison dates cannot be later than the selected date range.',
    );
    return false;
  }

  return true;
};

// Toggle the comparison checkbox
const toggleCompare = () => {
  localIsCompareChecked.value = !localIsCompareChecked.value;
  emit('toggleCompare', localIsCompareChecked.value);
};

// Apply preset dates
const applyPreset = (preset) => {
  localStartDate.value = preset.startDate;
  localEndDate.value = preset.endDate;
  onDateChange();
  emit('close');
};

// Close the modal
const closeModal = () => {
  emit('close');
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
