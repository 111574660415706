<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden py-2 px-2 md:px-4 bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 min-h-full flex-grow"
  >
    <!-- Header section with title -->
    <div class="text-base md:text-lg">Session Medium</div>
    <!-- ApexChart component with adjusted height -->
    <div class="flex-1 flex justify-center items-center flex-col lg:flex-row">
      <apexchart
        type="donut"
        :options="chartOptions"
        :series="chartSeries"
        class=""
      ></apexchart>
      <div
        class="text-xs md:text-base flex lg:flex-col gap-2 md:gap-0 flex-wrap justify-center"
      >
        <span
          v-for="(item, index) in labelData"
          :key="index"
          :style="{ color: colors[index] }"
        >
          ● {{ item }}
        </span>
      </div>
    </div>
    <!-- Data source label -->
    <div class="text-left text-base">Google Analytics</div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import axios from '@/utils/privateApiInstance';
import { useTeamStore } from '@/stores/teamStore';
import { formatNumber } from '@/utils/helperFunctions';
import { useDateStore } from '@/stores/dateStore';

// Reactive variables
const teamStore = useTeamStore();
const dateStore = useDateStore();
const currentTeam = ref(teamStore.current_team);
const data = ref();
const labelData = ref();
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
  },
  {
    immediate: true,
  },
);
const colors = ['#fbbf24', '#f59e0b', '#34d399', '#60a5fa', '#e11d48'];
// Chart configuration options
const chartOptions = computed(() => ({
  chart: {
    id: 'sessions-chart',
    type: 'donut',

    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },

  colors: colors,

  labels: chartLabels.value,
  legend: {
    show: false,
  },
  tooltip: {
    theme: 'dark',
  },
  noData: {
    text: 'Waiting for data...',
  },
  dataLabels: {
    enabled: false,
  },
}));
// Fetch session data from API
const fetchData = async () => {
  try {
    const response = await axios.get('/google-analytics/session-medium', {
      params: {
        team_id: currentTeam.value ? currentTeam.value.id : '',
        start_date: dateStore?.dashboardDate.currentStart,
        end_date: dateStore?.dashboardDate.currentEnd,
      },
    });

    data.value = JSON.parse(response.data?.items.data);
  } catch (error) {
    data.value = [];
  }
};
// Chart series data
const chartSeries = ref([]); // Placeholder data
const chartLabels = ref([]); // Placeholder labels
// Watch for changes in props to refetch data
watch(
  () => dateStore.dashboardDate, // Watch for changes in these values
  async () => {
    await fetchData(); // Ensure DOM updates before calling API
  },
  { immediate: true, deep: true },
);

// Watcher to update chart data and options
watch(
  () => data?.value,
  (newData) => {
    if (Array.isArray(newData)) {
      // // Extract session types and their counts
      const labels = newData.map((items) => items[0]);
      const series = newData.map((item) => item[1]); // Session counts
      labelData.value = newData.map(
        (item) => item[0].toUpperCase() + '-' + formatNumber(item[1]),
      );

      // // Update chart data
      chartLabels.value = labels;
      chartSeries.value = series;
    }
  },
  { immediate: true },
);
</script>
