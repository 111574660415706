<template>
  <div
    :class="[
      'flex flex-col  justify-center p-2 rounded-lg shadow-md transition duration-300 pt-3 text-base ',
      connectorStore.loading && !loading ? 'opacity-50' : '',
      bgClass,
    ]"
  >
    <!-- Display appropriate icon based on the 'name' prop -->
    <img
      src="../assets/google-ads.png"
      class="h-6 w-fit self-center mb-2"
      v-if="name === 'Google Ads'"
      :alt="name"
    />

    <img
      src="../assets/facebook_ads.png"
      class="h-6 w-fit self-center mb-2"
      v-else-if="name === 'Facebook Ads'"
      :alt="name"
    />

    <img
      src="../assets/google_analytics_4.png"
      class="h-6 w-fit self-center mb-2"
      v-else-if="name === 'Google Analytics'"
      :alt="name"
    />

    <img
      src="../assets/amazon.png"
      class="h-6 w-fit self-center mb-2"
      v-else-if="name === 'Amazon Ads'"
      :alt="name"
    />

    <img
      src="../assets/google-search-console.png"
      class="h-6 w-fit self-center mb-2"
      v-else-if="name === 'Google Search Console'"
      :alt="name"
    />
    <div v-if="completed" class="font-medium text-green-600 text-center">
      {{ name }} Account Connected
    </div>
    <div v-else>
      <div v-if="connectorIdFromStore">
        <div v-if="loading" class="mt-2"><StyledLoader /></div>
        <button
          v-else
          @click="fetchConnectCardUri"
          class="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 flex"
          :disabled="connectorStore.loading"
        >
          Authorize your {{ name }} account
        </button>
      </div>
      <div v-else>
        <!-- Show loader while the operation is in progress -->
        <div v-if="loading" class="mt-2"><StyledLoader /></div>
        <!-- Button to connect account  -->
        <button
          v-else
          @click="createConnector"
          class="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 text-wrap w-full text-sm md:text-base overflow-clip h-16"
          :disabled="connectorStore.loading"
        >
          Connect your {{ name }} account
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from '../utils/fivetranApiInstance';
import { useConnectorStore } from '@/stores/connectorStore';
import { ref, watch, computed } from 'vue';
import { toast } from 'vue3-toastify';
import StyledLoader from './StyledLoader.vue';
import { useTeamStore } from '@/stores/teamStore';
import axiosInstance from '@/utils/privateApiInstance';

// Define props and extract necessary data
const props = defineProps(['data']);
const { service, schemaName, name } = props.data;

// Initialize connector store and team store
const connectorStore = useConnectorStore();
const teamStore = useTeamStore();
const schema = ref('');
const loading = ref(false);
const completed = computed(() => connectorStore.completedConnectors[service]);
const bgClass = computed(() =>
  completed.value ? 'bg-green-200 h-full' : 'bg-gray-200 h-full',
);
const currentTeam = ref(teamStore.current_team);
const connectorIdFromStore = computed(
  () => connectorStore.connectorId[service],
);

// Watch for changes in the current team and handle schema updates
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
    schema.value = `${schemaName}${currentTeam.value?.id}`;
  },
  {
    immediate: true,
  },
);

// Function to fetch connect card URI
const fetchConnectCardUri = async () => {
  try {
    connectorStore.setLoading();
    loading.value = true;
    const response = await axios.post(
      `/connectors/${connectorIdFromStore.value}/connect-card`,
      {
        connect_card_config: {
          redirect_uri: 'https://app.dvio.one/dashboard',
        },
      },
    );
    const data = response.data?.data?.connect_card;
    const uri = data.uri;
    window.location.href = uri;
  } catch (error) {
    toast.error('Error authorizing account. Please try again.');
  } finally {
    connectorStore.setLoading();
    loading.value = false;
  }
};

const storeConnectorID = async (connectorId) => {
  try {
    const response = await axiosInstance.post('/settings/set-connector-id', {
      team_id: teamStore.current_team.id,
      connector_type: service,
      connector_id: connectorId,
    });
    if (response.data?.team) {
      teamStore.setTeams(response.data.team);
    }
  } catch (error) {
    console.error('Error updating connector schema. Please try again.');
  }
};
// Function to create a connector
const createConnector = async () => {
  if (!schema.value) {
    toast.error('Error creating connector. Please try again.');
    return;
  }
  connectorStore.setLoading();
  loading.value = true;

  try {
    const response = await axios.post('/connectors', {
      service,
      group_id: 'reverent_description',
      config: { schema: schema.value },
      connect_card_config: {
        redirect_uri: 'https://app.dvio.one/dashboard',
      },
    });
    const data = response.data?.data;

    if (data) {
      const connectorId = data.id;
      if (connectorId) {
        await storeConnectorID(connectorId);
        connectorStore.setConnectorId(service, connectorId);
      }
      const uri = data.connect_card.uri;
      window.location.href = uri;
    } else {
      toast.error('Error occurred. Please try again.');
    }
  } catch (error) {
    toast.error('Error creating connector. Please try again.');
  } finally {
    connectorStore.setLoading();
    loading.value = false;
  }
};
</script>
