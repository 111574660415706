<template>
  <div
    class="flex flex-col rounded-lg border bg-white dark:bg-gray-950 dark:text-white overflow-hidden p-2 dark:border-gray-700 cursor-default hover:scale-105 transition-transform w-full h-full"
  >
    <!-- Header section with title -->
    <div class="text-xs font-normal text-left mb-2 flex items-center gap-1">
      <img
        src="../assets/google-ads.png"
        alt="Google Ads"
        class="h-6 w-6 p-1"
        v-if="srcName == 'Google Ads'"
      />
      <img
        src="../assets/google_analytics_4.png"
        alt="Google Ads"
        class="h-6 w-6 p-1"
        v-if="srcName == 'Google Analytics'"
      />
      <img
        src="../assets/google-search-console.png"
        alt="Google Ads"
        class="h-6 w-6 p-1"
        v-if="srcName == 'Google Search Console'"
      />
      <span class="font-semibold">{{ srcName }}</span>
    </div>

    <!-- Growth value display -->
    <div
      class="flex-grow flex items-center justify-center text-lg md:text-2xl font-medium"
    >
      <span v-if="!loading" class="text-center"
        >{{ growth }}
        <p class="text-xs md:text-sm font-normal">
          {{ name.toLocaleUpperCase() }}
        </p>
      </span>
      <StyledLoader v-else />
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, watch, nextTick } from 'vue';
import axios from '../utils/privateApiInstance';
import StyledLoader from './StyledLoader.vue';
import { useTeamStore } from '@/stores/teamStore';
import { useDateStore } from '@/stores/dateStore';

const dateStore = useDateStore();
const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);

// Watch for changes in the team store and update the local ref
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
  },
  {
    immediate: true,
  },
);

// Define props
const props = defineProps({
  apiEndpoint: {
    type: String,
    required: true,
  },
  metricName: {
    type: String,
    required: true,
  },
  formatData: {
    type: Function,
    default: (data) => data,
  },
  srcName: {
    type: String,
    default: 'Google Ads',
  },
});

// Reactive variables
const name = props.metricName;
const growth = ref('');
const loading = ref(true);

// Fetch data from API
const fetchData = async () => {
  loading.value = true;
  try {
    const response = await axios.get(props.apiEndpoint, {
      params: {
        start_date: dateStore?.dashboardDate?.currentStart,
        end_date: dateStore?.dashboardDate?.currentEnd,
        team_id: currentTeam.value ? currentTeam.value.id : null,
      },
    });
    const data = response.data?.items;
    growth.value = props.formatData(data);
  } catch (error) {
    growth.value = '';
  } finally {
    loading.value = false;
  }
};

// Watch for changes in props to refetch data
watch(
  () => dateStore.dashboardDate, // Watch for changes in these values
  async () => {
    await nextTick(); // Ensure DOM updates before calling API
    await fetchData();
  },
  { immediate: true, deep: true },
);
</script>
