<template>
  <div
    class="flex flex-col rounded-lg border bg-white dark:bg-gray-950 overflow-hidden px-2 md:px-4 pt-4 dark:border-gray-700 cursor-default w-full h-full"
  >
    <div class="flex justify-between flex-wrap">
      <div class="text-base md:text-xl font-semibold mb-2 dark:text-white">
        {{ title }}
      </div>
      <div
        class="flex text-sm items-center text-gray-500 dark:text-gray-300 px-4 gap-1 mb-2"
      >
        <Calendar :size="16" />
        {{ formattedStartDate }} - {{ formattedEndDate }}
      </div>
    </div>
    <div v-if="!loading" class="p-2 border-b dark:border-gray-500">
      <div
        class="flex px-2 border-b dark:border-gray-500 mb-2 text-gray-700 dark:text-gray-300"
      >
        <div class="w-3/12">{{ column1 }}</div>
        <div class="w-5/12">{{ column2 }}</div>
        <div class="w-4/12 text-center">{{ column3 }}</div>
      </div>
      <div class="flex p-2" v-for="(item, index) in displayedData" :key="index">
        <div class="w-3/12 text-amber-500 text-xs lg:text-base">
          {{ item[Number(displayIndex[0])] }}
        </div>
        <div class="w-5/12 dark:text-white flex items-center gap-1 px-1">
          <div class="w-1/5 text-xs md:text-sm">
            {{ progressForItem(item).toFixed(0) }}%
          </div>
          <div
            class="relative bg-gray-200 dark:bg-gray-800 h-4 overflow-hidden w-4/5 rounded-md"
          >
            <div
              class="absolute h-full bg-amber-500 transition-all duration-300"
              :style="{ width: progressForItem(item) + '%' }"
            ></div>
          </div>
        </div>
        <div
          class="w-4/12 flex items-center gap-1 md:gap-2 text-xs md:text-sm lg:text-base dark:text-white"
          v-if="change"
        >
          <div class="w-2/4 text-end">
            {{
              formatter
                ? formatter(item[Number(displayIndex[1])])
                : item[Number(displayIndex[1])]
            }}
          </div>
          <div class="w-2/4">
            <div
              v-if="item[change] > 0"
              class="text-green-500 flex items-center"
            >
              <ArrowUp size="16" />{{ item[change]?.toFixed(1) }}%
            </div>
            <div v-else class="text-red-500 flex items-center">
              <ArrowDown size="16" />{{ item[change]?.toFixed(1) }}%
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex flex-grow justify-center">
      <StyledLoader />
    </div>
    <div class="self-end space-x-4 p-2 text-blue-500 text-sm">
      <button @click="prevPage" v-if="currentPage != 1">Previous</button>

      <button class="" @click="nextPage" v-if="currentPage != totalPages">
        See more
      </button>
    </div>
  </div>
</template>
<script setup>
import { useTeamStore } from '@/stores/teamStore';
import axios from '@/utils/privateApiInstance';
import StyledLoader from './StyledLoader.vue';
import { ArrowDown, ArrowUp, Calendar } from 'lucide-vue-next';
import { ref, computed, watch, nextTick } from 'vue';
import { useDateStore } from '@/stores/dateStore';
const teamStore = useTeamStore();
const loading = ref(false);
const currentTeam = ref(teamStore.current_team);
const dateStore = useDateStore();
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
  },
  {
    immediate: true,
    deep: true,
  },
);
const props = defineProps({
  title: String,
  apiUrl: String,
  column1: String,
  column2: String,
  column3: String,
  formatter: Function,
  displayIndex: Array,
  change: Number,
});

const itemsPerPage = 5;
const currentPage = ref(1);
const apiData = ref([]);
const fetchData = async () => {
  try {
    loading.value = true;
    const response = await axios.get(props.apiUrl, {
      params: {
        team_id: currentTeam.value?.id || 1,
        start_date: dateStore?.insightDate?.currentStart,
        end_date: dateStore?.insightDate?.currentEnd,
        past_start_date: dateStore.insightDate.pastStart,
        past_end_date: dateStore.insightDate.pastEnd,
      },
    });
    apiData.value = JSON.parse(response.data?.items.data);
  } catch (error) {
    console.error('Error fetching data');
    apiData.value = [];
  } finally {
    loading.value = false;
  }
};

watch(
  () => dateStore.insightDate, // Watch for changes in these values
  async () => {
    await nextTick(); // Ensure DOM updates before calling API
    await fetchData();
  },
  { immediate: true, deep: true },
);

const totalPages = computed(() =>
  Math.ceil(apiData.value?.length / itemsPerPage),
);
const startIndex = computed(() => (currentPage.value - 1) * itemsPerPage);

const displayedData = computed(() =>
  apiData.value?.slice(startIndex.value, startIndex.value + itemsPerPage),
);

const totalSum = computed(() => {
  return apiData.value.reduce((sum, item) => {
    return sum + (item[Number(props.displayIndex[1])] || 0);
  }, 0);
});

const progressForItem = (item) => {
  const itemValue = item[Number(props.displayIndex[1])] || 0;
  return totalSum.value > 0 ? (itemValue / totalSum.value) * 100 : 0;
};
const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

// Format the main date range
const formattedStartDate = computed(() => {
  const options = { year: 'numeric', month: 'short' };
  return new Date(dateStore?.insightDate?.pastStart).toLocaleDateString(
    'en-US',
    options,
  );
});

const formattedEndDate = computed(() => {
  const options = { year: 'numeric', month: 'short', year: 'numeric' };
  return new Date(dateStore?.insightDate?.currentEnd).toLocaleDateString(
    'en-US',
    options,
  );
});
</script>
