<template>
  <div
    class="rounded-lg overflow-hidden bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 flex flex-col px-2 md:px-4 py-4"
  >
    <!-- Header section with title -->
    <div class="flex justify-between flex-wrap">
      <div class="text-base md:text-xl font-semibold mb-2 dark:text-white">
        Channel Overview
      </div>
      <div
        class="flex text-sm items-center text-gray-500 dark:text-gray-300 px-4 gap-1 mb-2"
      >
        <Calendar :size="16" />
        {{ formattedStartDate }} - {{ formattedEndDate }}
      </div>
    </div>
    <div class="h-80" v-if="!loading">
      <apexchart
        type="bar"
        :options="chartOptions"
        :series="chartSeries"
        height="100%"
      ></apexchart>
    </div>
    <div v-else class="flex flex-1 justify-center">
      <StyledLoader />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import axios from '@/utils/privateApiInstance';
import { useTeamStore } from '@/stores/teamStore';
import { useDateStore } from '@/stores/dateStore';
import { Calendar } from 'lucide-vue-next';
import StyledLoader from '../StyledLoader.vue';
import { formatNumber } from '@/utils/helperFunctions';
const dateStore = useDateStore();

const chartSeries = ref([
  {
    name: 'Current',
    data: [],
  },
  {
    name: 'Past',
    data: [],
  },
]);
// Reactive variables
const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);
const data = ref();
const chartLabels = ref([]); // Placeholder labels
const loading = ref(false);
const change = ref([]);
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
  },
  {
    immediate: true,
  },
);
const colors = ['#f59e0b', '#fbbf24'];

const chartOptions = computed(() => ({
  chart: {
    id: 'channel-chart',
    type: 'bar',
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    labels: {
      show: true,
      rotate: 0,
      hideOverlappingLabels: false,
      trim: true,
      style: {
        colors: '#64748b',
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  grid: {
    show: false,
  },
  colors: colors,
  labels: chartLabels.value,
  tooltip: {
    theme: 'dark',
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      const current = formatNumber(w.globals.series[0][dataPointIndex]); // Current Total Users
      const past = formatNumber(w.globals.series[1][dataPointIndex]); // Past Total Users

      const growth = change.value[dataPointIndex];
      const xLabel = w.globals.labels[dataPointIndex];
      return `
        <div style="background-color:#2d3748;padding:25px;border-radius:8px;color:#f7fafc;min-width:180px;box-shadow:0 4px 8px rgba(0,0,0,0.4);">
    <div style="font-size:14px;margin-bottom:10px;color:#e2e8f0;">
      <strong>${xLabel}</strong>
    </div>

    <div style="display:flex;align-items:center;justify-content:space-between;margin-bottom:8px;font-size:13px;">
      <div style="display:flex;align-items:center;">
        <span style="background-color:#fbbf24;width:12px;height:12px;border-radius:50%;margin-right:8px;"></span>
        <span>Current</span>
      </div>
      <div style="color:#fff;"> (${current})</div>
    </div>
    
    <div style="display:flex;align-items:center;justify-content:space-between;margin-bottom:8px;font-size:13px;">
      <div style="display:flex;align-items:center;">
        <span style="background-color:#f59e0b;width:12px;height:12px;border-radius:50%;margin-right:8px;"></span>
        <span>Past</span>
      </div>
      <div style="color:#fff;"> (${past})</div>
    </div>
    
    <hr style="border-top:1px solid #4b5563;margin:10px 0;" />

    <div style="font-size:13px;display:flex;justify-content:space-between;">
      <span>Change</span>
      <span style="color: ${growth > 0 ? '#48bb78' : '#f56565'};">
        ${growth > 0 ? '+' : ''}<strong>${growth.toFixed(2)}%</strong>
      </span>
    </div>
  </div>
      `;
    },
  },
  noData: {
    text: 'Waiting for data...',
  },
  legend: {
    labels: {
      useSeriesColors: true,
    },
  },
  responsive: [
    {
      breakpoint: 768,
      options: {
        xaxis: {
          labels: {
            rotate: -45,
            style: {
              fontSize: '8px',
            },
          },
        },
      },
    },
  ],
}));

// Fetch channel data from API
const fetchData = async () => {
  try {
    loading.value = true;
    if (
      dateStore.insightDate.currentStart &&
      dateStore.insightDate.currentEnd
    ) {
      const response = await axios.get(
        '/google-analytics-insights/highest-converting-traffic-channel-metrics',
        {
          params: {
            team_id: currentTeam.value ? Number(currentTeam.value.id) : 0,
            start_date: dateStore.insightDate.currentStart,
            end_date: dateStore.insightDate.currentEnd,
            past_start_date: dateStore.insightDate.pastStart,
            past_end_date: dateStore.insightDate.pastEnd,
          },
        },
      );

      data.value = JSON.parse(response.data?.items.data);
    }
  } catch (error) {
    data.value = [];
  } finally {
    loading.value = false;
  }
};

// Watch for changes in props to refetch data
watch(
  () => dateStore.insightDate, // Watch for changes in these values
  async () => {
    await fetchData(); // Ensure DOM updates before calling API
  },
  { immediate: true, deep: true },
);

watch(
  () => data.value,
  (newData) => {
    if (Array.isArray(newData) && newData.length > 0) {
      const labels = newData.map((items) => items[0]);

      const currentTotalUsers = newData.map((item) => item[2]);
      const pastTotalUsers = newData.map((item) => item[1]);
      change.value = newData.map((item) => item[3]);
      chartLabels.value = labels;

      // Update chart series
      chartSeries.value = [
        {
          name: 'Past Total Users',
          data: pastTotalUsers,
        },
        {
          name: 'Current Total Users',
          data: currentTotalUsers,
        },
      ];
    }
  },
  { immediate: true },
);
// Format the main date range
const formattedStartDate = computed(() => {
  const options = { year: 'numeric', month: 'short' };
  return new Date(dateStore?.insightDate?.pastStart).toLocaleDateString(
    'en-US',
    options,
  );
});

const formattedEndDate = computed(() => {
  const options = { year: 'numeric', month: 'short', year: 'numeric' };
  return new Date(dateStore?.insightDate?.currentEnd).toLocaleDateString(
    'en-US',
    options,
  );
});
</script>
