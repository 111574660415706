import { calculateDate } from './helperFunctions';

export const presets = [
  {
    label: 'Past 7 days',
    startDate: calculateDate(-7),
    endDate: calculateDate(0),
  },
  {
    label: 'Past 14 days',
    startDate: calculateDate(-14),
    endDate: calculateDate(0),
  },
  {
    label: 'Past 30 days',
    startDate: calculateDate(-30),
    endDate: calculateDate(0),
  },
  {
    label: 'Past 90 days',
    startDate: calculateDate(-90),
    endDate: calculateDate(0),
  },
];
