<script setup>
import ChartLayout from '@/layouts/ChartLayout.vue';
import NavBarLayout from '@/layouts/NavBarLayout.vue';

import { useTeamStore } from '@/stores/teamStore';
import { computed, ref } from 'vue';
import { RouterLink } from 'vue-router';

const teamStore = useTeamStore();
const team = ref({});

// Computed property to check if we should show the button or the chart
const shouldShowButton = computed(() => {
  // Get the current team
  const currentTeam = teamStore.current_team;

  // If there's no current team, show the button
  if (!currentTeam) {
    return true;
  }
  // name.value = currentTeam.name;
  team.value = {
    name: currentTeam.name,
    id: currentTeam.id,
  };
  // Check if the current team's connectors are empty
  const connectorsEmpty = currentTeam.connectors.length === 0;

  // Check if any connector in the current team has a schema_name
  const anyConnectorHasSchemaName = currentTeam.connectors.some(
    (connector) => connector.schema_name,
  );

  // If the connectors are empty, show the button
  if (connectorsEmpty) {
    return true;
  }

  // If any connector has a schema_name, show the chart
  return !anyConnectorHasSchemaName;
});
</script>

<template>
  <div class="flex">
    <NavBarLayout />
    <div class="flex-1 dark:bg-black min-h-screen bg-gray-100">
      <div class="flex flex-col h-full mt-14 md:mt-0">
        <div
          v-if="shouldShowButton"
          class="flex flex-col justify-center items-center gap-1 flex-1 text-base md:text-lg dark:text-white"
        >
          <p>Connect your accounts first to see the dashboard</p>
          <RouterLink
            to="/team"
            class="px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 focus:bg-gray-700 active:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 transition ease-in-out duration-150 max-w-fit dark:bg-amber-500"
          >
            Go to Team Settings
          </RouterLink>
        </div>

        <ChartLayout v-else :team="team" />
      </div>
    </div>
  </div>
</template>
